<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ticket/list' }">票务</el-breadcrumb-item>
        <el-breadcrumb-item>查看</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="24" class="warp-main">
      <el-form ref="form" :model="form" label-width="150px" class="el-form-self">


        <el-row>
          <el-col>
            <el-form-item label="名称:" prop="name">
              <div class="view-text">{{form.name}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="序号:" prop="numberOrder">
              <div class="view-text">{{form.numberOrder}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="景点图片:" prop="imageUrl" :disabled=true>
              <el-upload
                class="companyNewsImage-uploader"
                :action="uploadFileURL"
                :show-file-list="false">
                <img v-if="imageUrl" :src="imageUrl" class="homeBannerImage">
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="图片外链:" prop="outerLinkUrl">
              <div class="view-text">{{form.outerLinkUrl}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="form-buttons">
          <el-form-item class="el-form-item-self">
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-row>
      </el-form>


    </el-col>
  </el-row>

</template>
<script>
  import {uploadFileURL,downloadFileURL} from '../../../api/system-api'
  import {reqGetTicketOne}   from '../../../api/ticket-api'
  export default {
    data() {
      return {
        filtersName:'',
        editPath:'/ticket/list',
        uploadFileURL:uploadFileURL,
        imageUrl: '',

        form: {
          id: 0,
          name: '',
          content: '',
          numberOrder: '',
          isUse:'',
          type: '',
          imageUrl: '',
          outerLink: '',
        },
      }
    },
    methods: {

      getTicket(id) {
        var that = this;
          reqGetTicketOne({id: id}).then((res) => {
          if(res.data.data.imageUrl){
            this.imageUrl=downloadFileURL + res.data.data.imageUrl + "/";
          }else {
            this.imageUrl='';
          }
          that.form = Object.assign({}, res.data.data);

        });
      },
    },
    mounted() {
      let id = this.$route.query.id;
      this.getTicket(id);
    }
  }
</script>
